import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  ComboBox,
  Label,
  PhoneFaxInput,
  SelectDropdown,
  Textbox,
} from '@gsa/afp-component-library';

import FieldFeedback from '../../../../components/FieldFeedback/FieldFeedback';
import SectionErrors from './SectionErrors';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import './AddressComponent.scss';
import DealershipDeliverySelector from '../../../order-detail/components/DealershipDeliverySelector';
import { getStateCodeOnly } from '../../../../utilities/deliveryAddressUtils';
import { REQUISITION_TYPE } from '../../../non-standard-purchase/constants';

const getLabelValueFromItem = (item) => {
  return {
    label: item.description,
    value: item.code,
  };
};

const getValuesFromLabels = (item) => {
  return {
    label: item.description,
    value: `${item.code}:${item.description}`,
  };
};

const isElectricVehicle = (selectedContract) => {
  const evFuelCodes = ['70', '71', '72', '73'];
  let isEV = false;
  selectedContract.minimumRequiredOptions.forEach((option) => {
    option.contractLineFuel.forEach((fuel) => {
      if (evFuelCodes.includes(fuel.fuelCode)) {
        isEV = true;
      }
    });
  });
  return isEV;
};

const AddressComponent = ({
  section,
  countriesData,
  callingCodes,
  statesData,
  handleFieldChange,
  handleAddressCheckbox,
  addressFieldState,
  handleInputValidations,
  visibility,
  feedbackText,
  handleOnBlur,
  deliveryAddressOption,
  standardItemCodeFASTDescription,
  selectedContract,
  handleStepper,
  handleDealershipDeliveryAddressChange,
  requisitionType = 'STANDARD',
}) => {
  const deliveryBannerText = (deliveryOption, standardItemSubCategory) => {
    return (
      <div className="delivery-banner-info">
        {deliveryOption?.optionCode && (
          <>
            You&apos;ve selected{' '}
            <b>
              {deliveryOption?.optionCode}-{deliveryOption?.optionDescription}
            </b>
            {standardItemSubCategory && (
              <>
                {' '}
                for a <b>{standardItemSubCategory}</b>
              </>
            )}
            .{' '}
          </>
        )}
        <span>
          Enter your delivery address below. (The delivery address is the final
          destination address and cannot be a PO Box, APO, FPO, etc.) If
          you&apos;d like to change your delivery selection, please revisit the{' '}
          <span
            data-testid="compare-and-select-navigation"
            onClick={() => handleStepper(0)}
            className="notification-anchor"
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleStepper(0);
            }}
          >
            Compare and Select
          </span>{' '}
          page prior to order placement.
        </span>
      </div>
    );
  };

  const deliveryBannerTextDDR = (deliveryOpts, fastCode, isConus) => {
    const about = (
      <>
        You&apos;ve selected{' '}
        <b>
          {deliveryOpts?.optionCode}-{deliveryOpts?.optionDescription}
        </b>
        {fastCode && (
          <>
            {' '}
            for a <b>{fastCode}</b>
          </>
        )}
        .{' '}
      </>
    );
    return (
      <>
        <Alert type="info">
          {about}Select your {isConus ? 'STATE and ' : ''}CITY below, then
          choose from a list of available dealerships. If you’d like to change
          your delivery option selection, please revisit the{' '}
          <span
            data-testid="compare-and-select-navigation-ddr"
            onClick={() => handleStepper(0)}
            className="notification-anchor"
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleStepper(0);
            }}
          >
            Compare and Select
          </span>{' '}
          page prior to order placement.
        </Alert>
      </>
    );
  };

  const dealerDeliveryBannerText = (isConus) => {
    return (
      <>
        <Alert type="info">
          Dealership delivery is standard for this vehicle. Select your{' '}
          {isConus ? 'STATE and ' : ''}CITY below, then choose from a list of
          available dealerships. If you’d like to change your delivery option
          selection, please revisit the{' '}
          <span
            data-testid="compare-and-select-navigation-ddr"
            onClick={() => handleStepper(0)}
            className="notification-anchor"
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleStepper(0);
            }}
          >
            Compare and Select
          </span>{' '}
          page prior to order placement.
        </Alert>
      </>
    );
  };

  const valueFrmStorage = sessionStorage.getItem('selectedState');
  const selectedStateFromPurchase = valueFrmStorage?.substring(
    valueFrmStorage.indexOf('-') + 1,
  );

  const validZipCodeCountries = [
    'US',
    'PR',
    'VI',
    'GU',
    'MP',
    'AS',
    'FM',
    'MH',
    'PW',
  ];

  const internationalAddress =
    addressFieldState.countryCode &&
    !validZipCodeCountries.includes(addressFieldState.countryCode);

  const selectedCountryPurchase = sessionStorage.getItem('foriegnCountry');
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const {
    currentStandardItem,
    // draftRequisition,
    requisitionStateContext,
    mailingStateContext,
    // deliveryStateContext,
    isDomesticShipment,
  } = state;

  let countriesModifiedData = countriesData.map(getLabelValueFromItem);
  countriesModifiedData.sort((a, b) => a.label.localeCompare(b.label));
  const domesticOptions = ['US', 'PR', 'GU', 'VI', 'MP', 'AS'];

  const validateSelectedData = (data, isDomestic) => {
    // data filtered for US States and Territories
    const filteredCountriesData = data.filter((item) => {
      const checkTerritories = domesticOptions.find((li) => {
        return item.value === li;
      });

      const checkCondition = isDomestic ? checkTerritories : !checkTerritories;
      if (checkCondition) {
        return item;
      }
      return null;
    });
    // data mapped with default selected
    const dataWithDefaultSelected = filteredCountriesData.map((item) => {
      if (item.value === selectedStateFromPurchase) {
        const newObj = { ...item, defaultValue: true };
        return newObj;
      }
      return item;
    });

    return dataWithDefaultSelected;
  };

  if (section.title === 'DELIVERY ADDRESS') {
    countriesModifiedData = validateSelectedData(
      countriesModifiedData,
      isDomesticShipment ?? selectedStateFromPurchase,
    );
  }

  if (
    selectedStateFromPurchase === 'Select State' ||
    selectedCountryPurchase === 'Select Country'
  ) {
    countriesModifiedData.unshift({
      label: 'Select Country',
      value: 'Select Country',
      defaultValue: true,
    });
  }
  const callingCodesModifiedData = callingCodes.map(getValuesFromLabels);
  callingCodesModifiedData.sort((a, b) => a.label.localeCompare(b.label));

  const statesModifiedData =
    statesData && statesData.map(getLabelValueFromItem);

  statesModifiedData.sort((a, b) => a.label.localeCompare(b.label));
  statesModifiedData.unshift({
    label: 'Select State ',
    value: 'Select State ',
    defaultValue: true,
  });

  const handleLastNameInputValidation = (e) => {
    handleInputValidations(e, 'lastName', true, section.id);
  };

  const handleFirstNameInputValidation = (e) => {
    handleInputValidations(e, 'firstName', true, section.id);
  };

  const handleEmailAddressInputValidation = (e) => {
    handleInputValidations(e, 'email', true, section.id);
  };

  const handleEntityInputValidation = (e) => {
    handleInputValidations(e, 'entityName', true, section.id);
  };

  const isDealerSin = () => {
    if (
      !currentStandardItem ||
      !currentStandardItem.vehicleTypeCode ||
      !currentStandardItem.vehicleTypeCode.tags ||
      !currentStandardItem.vehicleTypeCode.tags.value
    )
      return false;
    return currentStandardItem.vehicleTypeCode.tags.value.some(
      (tag) => tag.toLowerCase() === 'dealer',
    );
  };

  // const handleAgencyOrganizationNameInputValidation = (e) => {
  //   handleInputValidations(e, 'entityName', true, section.id);
  // };

  /* useEffect(() => {
    const checkDraftData = draftRequisition && draftRequisition?.requisitionId;

    if (checkDraftData) {
      let reqAgencyOrganizationName = '';
      let mailingAgencyOrganizationName = '';
      let deliveryAgencyOrganizationName = '';

      if (checkDraftData?.requisitionAddress?.entityName?.length !== 0) {
        reqAgencyOrganizationName =
          checkDraftData.requisitionAddress?.entityName;
      }
      if (checkDraftData?.mailingAddress?.entityName?.length !== 0) {
        mailingAgencyOrganizationName =
          checkDraftData.mailingAddress?.entityName;
      }
      if (checkDraftData?.deliveryAddress?.entityName?.length !== 0) {
        deliveryAgencyOrganizationName =
          checkDraftData.deliveryAddress?.entityName;
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE,
        payload: {
          ...requisitionStateContext,
          agencyOrganizationName: reqAgencyOrganizationName,
        },
      });

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
        payload: {
          ...mailingStateContext,
          agencyOrganizationName: mailingAgencyOrganizationName,
        },
      });

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
        payload: {
          ...deliveryStateContext,
          agencyOrganizationName: deliveryAgencyOrganizationName,
        },
      });
    }
  }, [draftRequisition]); */

  const errorCnt = useMemo(
    () => Object.values(visibility).filter((error) => !!error).length,
    [visibility],
  );

  const useSameAddress = useMemo(() => {
    if (section.title === 'MAILING ADDRESS') {
      return addressFieldState.isMailingSameAsRequisition;
    }
    if (section.title === 'DELIVERY ADDRESS') {
      return addressFieldState.isDeliverySameAsRequisition;
    }
    return false;
  }, [section.title, addressFieldState]);

  const checkForMilitaryOrDiplomatic = useMemo(() => {
    if (
      (section.title === 'REQUISITIONING ADDRESS' &&
        requisitionStateContext.isMilitary) ||
      (section.title === 'MAILING ADDRESS' && mailingStateContext.isMilitary)
    ) {
      return true;
    }
    return false;
  }, [
    section.title,
    requisitionStateContext.isMilitary,
    mailingStateContext.isMilitary,
  ]);

  const sameAsReqValidations = useMemo(() => {
    if (
      section.title === 'MAILING ADDRESS' ||
      (section.title === 'DELIVERY ADDRESS' &&
        !requisitionStateContext.isMilitary)
    ) {
      return true;
    }
    return false;
  }, [section.title, requisitionStateContext.isMilitary]);

  const militaryCheckboxHandler = (checkMilitary) => {
    if (section.title === 'REQUISITIONING ADDRESS') {
      const reqState = { ...addressFieldState, isMilitary: checkMilitary };
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE,
        payload: reqState,
      });
    } else if (section.title === 'MAILING ADDRESS') {
      const mailingState = { ...addressFieldState, isMilitary: checkMilitary };
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
        payload: mailingState,
      });
    }
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_REQUISITION_ADDRESS_HAS_ERROR,
      payload: [],
    });
  };

  const postOfficeLabels = [
    {
      value: 'select',
      label: 'select',
      defaultValue: true,
    },
    { value: 'APO AE', label: 'Army/Air Post Office (APO AE)' },
    { value: 'FPO AP', label: 'Fleet Post Office (FPO AP)' },
    { value: 'DPO', label: 'Diplomatic Post Office (DPO)' },
  ];

  const getCountryCodeValue = useCallback(
    (fieldValue) => {
      return (
        (callingCodesModifiedData || []).filter((i) => {
          return (
            i.value?.split(':')[0] === fieldValue &&
            i.value?.indexOf('Canada') === -1 // sorry canada
          );
        })?.[0]?.value || ''
      );
    },
    [callingCodesModifiedData],
  );

  const checkDefaultValue = () => {
    const isDomesticOptions = ['PR', 'GU', 'VI', 'MP', 'AS'];
    const check = isDomesticOptions.includes(selectedStateFromPurchase);

    // condition if selected shipment is domestic and Selected US Territories
    if (isDomesticShipment && check && section.title === 'DELIVERY ADDRESS') {
      return selectedStateFromPurchase;
    }

    // condition if selected shipment is domestic and Selected US State
    if (
      selectedStateFromPurchase !== 'Select State' &&
      !check &&
      section.title === 'DELIVERY ADDRESS'
    ) {
      return addressFieldState?.countryCode?.length > 0
        ? addressFieldState.countryCode
        : 'US';
    }
    // condition if selected shipment is non domestic and NOT US or US Territories
    if (
      !domesticOptions.includes(selectedCountryPurchase) &&
      !isDomesticShipment &&
      section.title === 'DELIVERY ADDRESS'
    ) {
      return selectedCountryPurchase;
    }
    return addressFieldState.countryCode || 'US';
  };

  const isConus = () => {
    const OCONUSStateCodes = [
      'AA',
      'AE',
      'AP',
      'FM',
      'GE',
      'IT',
      'MH',
      'NU',
      'PW',
      'AK',
      'AS',
      'GU',
      'HI',
      'MP',
      'PR',
      'UM',
      'VI',
    ];
    let defaultStateCode = sessionStorage.getItem('selectedState');
    if (defaultStateCode && defaultStateCode.length > 2) {
      defaultStateCode = defaultStateCode.substring(
        defaultStateCode.length - 2,
      );
    }
    return !OCONUSStateCodes.includes(defaultStateCode);
  };

  const checkDefaultPostOfficeValue = () => {
    if (addressFieldState.postOffice) {
      return addressFieldState.postOffice;
    }

    if (addressFieldState?.isMilitary && addressFieldState.addressLine2) {
      return addressFieldState.addressLine2;
    }
    return 'select';
  };

  return (
    <>
      <div data-testid={`deliveryAddressForm-${section.id}`}>
        <div className="address-main-section">
          {requisitionType === 'STANDARD' && (
            <div className="address-title">{section.title}</div>
          )}
          {section.title === 'DELIVERY ADDRESS' &&
            state.requisitionType !== REQUISITION_TYPE.AREQ && (
              <>
                {deliveryAddressOption?.optionCode?.startsWith('CNS') ||
                (!deliveryAddressOption?.optionCode && !isDealerSin()) ? (
                  <Alert type="info">
                    {deliveryBannerText(
                      deliveryAddressOption,
                      standardItemCodeFASTDescription,
                    )}
                  </Alert>
                ) : null}
                {deliveryAddressOption?.optionCode?.startsWith('DDR') &&
                  deliveryBannerTextDDR(
                    deliveryAddressOption,
                    standardItemCodeFASTDescription,
                    isConus(),
                  )}
                {!deliveryAddressOption?.optionCode &&
                  isDealerSin() &&
                  dealerDeliveryBannerText(isConus())}
              </>
            )}
          {errorCnt > 0 && !useSameAddress && (
            <SectionErrors errors={errorCnt} />
          )}
          {requisitionType === 'STANDARD' && <p>{section.subTitle}</p>}
        </div>

        {sameAsReqValidations &&
          !['MULTIPLE_AWARD_SCHEDULES', 'URGENT_REQUIREMENT'].includes(
            requisitionType,
          ) && (
            <div className="same-address-confirm-section">
              <input
                data-testid={`address-confirm-section-${section.id}`}
                id={`same-address-checkbox-btn-${section.id}`}
                aria-label={`${section.accessibilityId} section same as requisitioning address`}
                type="checkbox"
                value="confirm-address"
                className="same-address-checkbox"
                checked={useSameAddress}
                onChange={() => handleAddressCheckbox(section.id)}
              />
              <label
                htmlFor={`same-address-checkbox-btn-${section.id}`}
                className="checkbox-description"
              >
                Same as requisitioning address
              </label>
            </div>
          )}

        {requisitionType === 'STANDARD' &&
          (section.title === 'REQUISITIONING ADDRESS' ||
            section.title === 'MAILING ADDRESS') &&
          !addressFieldState.isMailingSameAsRequisition && (
            <>
              <div className="military-address-confirm-section">
                <input
                  data-testid={`military-address-confirm-section-${section.accessibilityId}`}
                  type="checkbox"
                  id={`confirm-address-${section.id}`}
                  aria-label={`${section.accessibilityId} section this is a military or diplomatic address`}
                  className="military-address-checkbox"
                  checked={addressFieldState.isMilitary}
                  onChange={() => {
                    militaryCheckboxHandler(!addressFieldState.isMilitary);
                  }}
                />
                <label
                  htmlFor={`confirm-address-${section.accessibilityId}`}
                  className="checkbox-description"
                >
                  This is a military or diplomatic address
                </label>
              </div>
              {section.title === 'REQUISITIONING ADDRESS' &&
                addressFieldState.isMilitary && (
                  <Alert type="info">
                    Each shipment to an APO/FPO needs the recipient&apos;s rank,
                    name, unit, and the APO/FPO/DPO address with the 9-digit ZIP
                    code. Mail and shipments must be addressed to a specific
                    person; addressing to &quot;Any Service Member&quot; is no
                    longer permitted. To prevent mail from entering foreign mail
                    networks, do not include city or country names in APO/FPO
                    shipping addresses.
                  </Alert>
                )}
            </>
          )}

        <form>
          {selectedContract?.makeCode &&
            !deliveryAddressOption?.optionCode?.startsWith('CNS') &&
            (deliveryAddressOption?.optionCode?.startsWith('DDR') ||
              isDealerSin()) && (
              <DealershipDeliverySelector
                make={selectedContract?.makeCode}
                isEV={isElectricVehicle(selectedContract)}
                deliveryAddressOption={deliveryAddressOption}
                contractModelName={selectedContract?.modelName}
                deliveryDealership={addressFieldState?.deliveryDealership}
                handleDealershipDeliveryAddressChange={
                  handleDealershipDeliveryAddressChange
                }
                section={section}
              />
            )}
          {!useSameAddress && !checkForMilitaryOrDiplomatic && (
            <>
              <div id="address-section-block">
                <div
                  data-testid={`address-section-${section.id}`}
                  className="address-container"
                >
                  <div
                    className="country"
                    data-testid={`country-section-${section.id}`}
                  >
                    <Label
                      htmlFor="address-label"
                      required
                      className={[
                        'address-label',
                        visibility.country === 'error'
                          ? 'usa-label--error'
                          : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      Country
                    </Label>
                    <div className="country-section">
                      <FieldFeedback
                        text={feedbackText.countryCode}
                        visible={visibility.countryCode}
                        id={`countryFeedback-${section.accessibilityId}`}
                        applyTo={(child) =>
                          child.props.name === `country-${section.id}`
                        }
                      >
                        <ComboBox
                          data-testid={`select-country-${section.id}`}
                          aria-label={`${section.id} requisition address country code`}
                          name={`country-${section.id}`}
                          options={countriesModifiedData}
                          defaultValue={checkDefaultValue()}
                          className="field-commom-width"
                          onChange={(e) => {
                            // e would be undefined when the combobox is cleared,
                            // so it should be safe to assume that it's the same as
                            // the 'onblur' event
                            if (!e) {
                              handleOnBlur();
                              handleFieldChange('', 'countryCode', section.id);
                            } else {
                              let val = e || '';
                              if (val === 'Select Country') val = '';
                              handleFieldChange(val, 'countryCode', section.id);
                            }
                          }}
                        />
                      </FieldFeedback>
                    </div>
                  </div>

                  <div className="agency-organization-name">
                    <Label
                      htmlFor="address-label"
                      required
                      className="address-label"
                    >
                      Agency/Organization name
                    </Label>
                    <div>
                      <FieldFeedback
                        text={feedbackText.entityName}
                        visible={visibility.entityName}
                        id={`entityAddressFeedback-${section.accessibilityId}`}
                        applyTo={(child) => child.props.type === 'text'}
                      >
                        <Textbox
                          id={`entity-${section.accessibilityId}`}
                          aria-label={`${section.id} entity name`}
                          value={addressFieldState.entityName}
                          data-testid={`entity-${section.id}`}
                          key="entityName"
                          name="entityName"
                          onChange={handleEntityInputValidation}
                          onBlur={() => {
                            handleOnBlur();
                          }}
                        />
                      </FieldFeedback>
                    </div>
                  </div>

                  <div className="street-address">
                    <Label
                      htmlFor="address-label"
                      required
                      className={[
                        'address-label',
                        visibility.addressLine1 === 'error'
                          ? 'usa-label--error'
                          : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      Address line 1
                    </Label>
                    <div className="typeahead-section field-commom-width ">
                      <FieldFeedback
                        text={feedbackText.addressLine1}
                        visible={visibility.addressLine1}
                        id={`address1Feedback-${section.accessibilityId}`}
                        applyTo={(child) => child.props.type === 'text'}
                      >
                        <Textbox
                          id={`addressLine1-${section.accessibilityId}`}
                          aria-label={`${section.id} Address line 1`}
                          required
                          value={addressFieldState.addressLine1}
                          key="addressLine1"
                          data-testid={`addressLine1-${section.id}`}
                          name="addressLine1"
                          onChange={(e) => {
                            handleFieldChange(
                              e.target.value,
                              'addressLine1',
                              section.id,
                            );
                          }}
                          onBlur={() => {
                            handleOnBlur();
                          }}
                        />
                      </FieldFeedback>
                    </div>
                    <div className="field-commom-width ">
                      <Label htmlFor="address-label" className="address-label">
                        Address line 2
                      </Label>
                      <Textbox
                        id={`address2-${section.accessibilityId}`}
                        aria-label={`${section.id} Address line 2`}
                        type="text"
                        data-testid={`address2-${section.id}`}
                        value={addressFieldState.addressLine2}
                        onChange={(e) => {
                          handleFieldChange(
                            e.target.value,
                            'addressLine2',
                            section.id,
                          );
                        }}
                        onBlur={() => {
                          handleOnBlur();
                        }}
                      />
                    </div>
                  </div>
                  <div className="city-section">
                    <Label
                      htmlFor="address-label"
                      required
                      className={[
                        'address-label',
                        visibility.city === 'error' ? 'usa-label--error' : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      City
                    </Label>
                    <div>
                      <FieldFeedback
                        text={feedbackText.city}
                        visible={visibility.city}
                        id={`cityAddressFeedback-${section.accessibilityId}`}
                        applyTo={(child) => child.props.type === 'text'}
                      >
                        <Textbox
                          id={`city-${section.accessibilityId}`}
                          aria-label={`${section.id} city`}
                          required
                          value={addressFieldState.city}
                          data-testid={`city-${section.id}`}
                          key="city"
                          name="city"
                          className="field-commom-width"
                          onChange={(e) => {
                            handleInputValidations(
                              e.target.value,
                              'city',
                              true,
                              section.id,
                            );
                          }}
                          onBlur={() => {
                            handleOnBlur();
                          }}
                        />
                      </FieldFeedback>
                    </div>
                  </div>
                  <div className="state-section">
                    <div className="label-section">
                      <Label
                        htmlFor="address-label"
                        required
                        className={[
                          'address-label',
                          visibility.stateCode === 'error'
                            ? 'usa-label--error'
                            : '',
                        ]
                          .filter((i) => i !== '')
                          .join(' ')}
                      >
                        State
                      </Label>
                    </div>
                    <FieldFeedback
                      text={feedbackText.stateCode}
                      visible={visibility.stateCode}
                      id={`stateFeedback-${section.accessibilityId}`}
                      applyTo={(child) => 'containerClassName' in child.props}
                    >
                      <SelectDropdown
                        data-testid={`state-section-${section.id}`}
                        name="state"
                        required
                        aria-label={`${section.id} state`}
                        className="field-commom-width"
                        onChange={(e) => {
                          handleFieldChange(
                            e.target.value,
                            'stateCode',
                            section.id,
                          );
                        }}
                        onBlur={() => {
                          handleOnBlur();
                        }}
                        value={getStateCodeOnly(addressFieldState?.stateCode)}
                        options={statesModifiedData}
                      />
                    </FieldFeedback>
                  </div>
                  <div className="zip-section">
                    <Label
                      htmlFor="address-label"
                      required={!internationalAddress}
                      className={[
                        'address-label',
                        visibility.zipcode === 'error'
                          ? 'usa-label--error'
                          : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      {internationalAddress ? 'Postal Code' : 'Zip Code'}
                    </Label>
                    {internationalAddress ? (
                      <FieldFeedback
                        text={feedbackText.zipcode}
                        visible={visibility.zipcode}
                        id={`postalCodeFeedback-${section.accessibilityId}`}
                        applyTo={(child) => child?.props?.type === 'text'}
                      >
                        <div className="postal-section-input">
                          <Textbox
                            id={`postalcode-${section}`}
                            aria-label={`${section.id} zip code`}
                            required={!internationalAddress}
                            data-testid={`zipcode-${section.id}`}
                            value={addressFieldState.zipcode}
                            key="zipcode"
                            name="zipcode"
                            onChange={(e) => {
                              handleFieldChange(
                                e.target.value,
                                'zipcode',
                                section.id,
                              );
                            }}
                            onBlur={() => {
                              handleOnBlur();
                            }}
                          />
                        </div>
                      </FieldFeedback>
                    ) : (
                      <FieldFeedback
                        text={feedbackText.zipcode}
                        visible={visibility.zipcode}
                        id={`zip1AddressFeedback-${section.accessibilityId}`}
                        applyTo={(child) => child?.props?.type === 'text'}
                      >
                        <div className="zip-section-input field-common-width">
                          <Textbox
                            id={`zipcode-${section.accessibilityId}`}
                            aria-label={`${section.id} zip code`}
                            required
                            data-testid={`zipcode-${section.id}`}
                            className="field-common-width"
                            value={addressFieldState.zipcode}
                            key="zipcode"
                            name="zipcode"
                            onChange={(e) => {
                              handleFieldChange(
                                e.target.value,
                                'zipcode',
                                section.id,
                              );
                            }}
                            onBlur={() => {
                              handleOnBlur();
                            }}
                          />
                        </div>
                      </FieldFeedback>
                    )}
                  </div>
                </div>
              </div>

              <div className="point-of-contact">
                {requisitionType === 'STANDARD' ? (
                  <label htmlFor="address-label" className="address-label">
                    Point of Contact
                  </label>
                ) : (
                  <div className="urg-req-deliver-address-title">
                    DELIVERY POINT OF CONTACT
                  </div>
                )}
              </div>
              <div className="contact-details">
                <div className="first-name">
                  <Label
                    htmlFor="address-label"
                    required
                    className={[
                      'address-label',
                      visibility.firstName === 'error'
                        ? 'usa-label--error'
                        : '',
                    ]
                      .filter((i) => i !== '')
                      .join(' ')}
                  >
                    First name
                  </Label>
                  <div>
                    <FieldFeedback
                      text={feedbackText.firstName}
                      visible={visibility.firstName}
                      id={`firstNameFeedback-${section.accessibilityId}`}
                      applyTo={(child) => child.props.type === 'text'}
                    >
                      <Textbox
                        id={`firstName-${section.accessibilityId}`}
                        aria-label={`${section.id} first name`}
                        value={addressFieldState.firstName}
                        data-testid={`first-name-${section.id}`}
                        name="firstName"
                        key="firstName"
                        onChange={handleFirstNameInputValidation}
                        onBlur={() => {
                          handleOnBlur();
                        }}
                      />
                    </FieldFeedback>
                  </div>
                </div>
                <div className="last-name">
                  <Label
                    htmlFor="address-label"
                    required
                    className={[
                      'address-label',
                      visibility.lastName === 'error' ? 'usa-label--error' : '',
                    ]
                      .filter((i) => i !== '')
                      .join(' ')}
                  >
                    Last name
                  </Label>
                  <div>
                    <FieldFeedback
                      text={feedbackText.lastName}
                      visible={visibility.lastName}
                      id={`lastNameFeedback-${section.accessibilityId}`}
                      applyTo={(child) => child.props.type === 'text'}
                    >
                      <Textbox
                        id={`lastName-${section.accessibilityId}`}
                        aria-label={`${section.id} last name`}
                        data-testid={`last-name-${section.id}`}
                        value={addressFieldState.lastName}
                        name="lastName"
                        key="lastName"
                        onChange={handleLastNameInputValidation}
                        onBlur={() => {
                          handleOnBlur();
                        }}
                      />
                    </FieldFeedback>
                  </div>
                </div>
              </div>
              <div className="email-address">
                <Label
                  htmlFor="address-label"
                  required
                  className={[
                    'address-label',
                    visibility.email === 'error' ? 'usa-label--error' : '',
                  ]
                    .filter((i) => i !== '')
                    .join(' ')}
                >
                  Email
                </Label>
                <div>
                  <FieldFeedback
                    text={feedbackText.email}
                    visible={visibility.email}
                    id={`emailAddressFeedback-${section.accessibilityId}`}
                    applyTo={(child) => child.props.type === 'text'}
                  >
                    <Textbox
                      id={`email-${section.accessibilityId}`}
                      aria-label={`${section.id} email`}
                      value={addressFieldState.email}
                      data-testid={`email-address-${section.id}`}
                      key="email"
                      name="email"
                      onChange={handleEmailAddressInputValidation}
                      onBlur={() => {
                        handleOnBlur();
                      }}
                    />
                  </FieldFeedback>
                </div>
              </div>

              <div className="point-of-contact-details phone-input">
                <PhoneFaxInput
                  type="phone"
                  countryCodeProps={{
                    id: `country-code-${section.accessibilityId}`,
                    options: callingCodesModifiedData,
                    errorMessage: visibility.phoneCountryCode
                      ? feedbackText.phoneCountryCode
                      : null,
                    value: getCountryCodeValue(
                      addressFieldState.phoneCountryCode,
                    ),
                    defaultValue: getCountryCodeValue(
                      addressFieldState.phoneCountryCode,
                    ),
                    label: 'Country code',
                    required: true,
                    'aria-label': `${section.id} country code`,
                    onChange(newValue) {
                      if (!newValue) {
                        handleFieldChange('', 'phoneCountryCode', section.id);
                      } else {
                        handleFieldChange(
                          newValue?.split(':')[0] || '',
                          'phoneCountryCode',
                          section.id,
                        );
                      }
                    },
                    'data-testid': `country-code-${section.id}`,
                  }}
                  phoneFaxProps={{
                    errorMessage: visibility.phoneNumber
                      ? feedbackText.phoneNumber
                      : null,
                    label: 'Phone',
                    required: true,
                    'aria-label': `${section.id} phone number`,
                    defaultValue: addressFieldState.phoneNumber,
                    onChange(e) {
                      handleFieldChange(
                        e.target.value,
                        'phoneNumber',
                        section.id,
                      );
                    },
                    'data-testid': `phoneNumber-${section.id}`,
                  }}
                  extensionProps={{
                    errorMessage: visibility.phoneExtension
                      ? feedbackText.phoneExtension
                      : null,
                    defaultValue: addressFieldState.phoneExtension,
                    label: 'Extension',
                    'aria-label': `${section.id} phone extension number`,
                    onChange(e) {
                      handleFieldChange(
                        e.target.value,
                        'phoneExtension',
                        section.id,
                      );
                    },
                    'data-testid': `extension1-${section.id}`,
                  }}
                />
              </div>

              <div className="fax-number phone-input">
                <PhoneFaxInput
                  type="fax"
                  countryCodeProps={{
                    id: `country-code-fax-${section.accessibilityId}`,
                    name: 'faxCountryCode',
                    options: callingCodesModifiedData,
                    errorMessage: visibility.faxCountryCode
                      ? feedbackText.faxCountryCode
                      : null,
                    value: getCountryCodeValue(
                      addressFieldState.faxCountryCode,
                    ),
                    defaultValue: getCountryCodeValue(
                      addressFieldState.faxCountryCode,
                    ),
                    onChange(newValue) {
                      if (!newValue) return;
                      handleFieldChange(
                        newValue?.split(':')[0] || '',
                        'faxCountryCode',
                        section.id,
                      );
                    },
                  }}
                  phoneFaxProps={{
                    defaultValue: addressFieldState.faxNumber,
                    errorMessage: visibility.faxNumber
                      ? feedbackText.faxNumber
                      : null,
                    label: 'Fax',
                    'aria-label': `${section.id} fax number`,
                    onChange(e) {
                      handleFieldChange(
                        e.target.value,
                        'faxNumber',
                        section.id,
                      );
                    },
                    'data-testid': `address-fax2-section-mailing-${section.id}`,
                  }}
                  extensionProps={{
                    errorMessage:
                      visibility.faxExtension === 'error'
                        ? feedbackText.faxExtension
                        : null,
                    defaultValue: addressFieldState.faxExtension,
                    'aria-label': `${section.id} fax extension number`,
                    onChange(e) {
                      handleFieldChange(
                        e.target.value,
                        'faxExtension',
                        section.id,
                      );
                    },
                    'data-testid': `extension2-${section.id}`,
                  }}
                />
              </div>
            </>
          )}
        </form>
        {!useSameAddress && checkForMilitaryOrDiplomatic && (
          <form>
            <div
              data-testid={`military-address-section-${section.id}`}
              className="address-container"
            >
              {section.title !== 'DELIVERY ADDRESS' && (
                <>
                  <div className="agency-organization-name common-style">
                    <Label
                      htmlFor="rank-and-full-name-label"
                      required
                      className={[
                        'address-label',
                        visibility?.rankAndFullName === 'error'
                          ? 'usa-label--error'
                          : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      Rank and full name
                    </Label>
                    <div className="typeahead-section">
                      <FieldFeedback
                        text={feedbackText.rankAndFullName}
                        visible={visibility.rankAndFullName}
                        id="rankAndFullNameFeedback"
                        applyTo={(child) => child.props.type === 'text'}
                      >
                        {/* prettier-ignore  TODO -- verify new schema */}
                        <Textbox
                          id="rankAndFullName"
                          value={addressFieldState.rankAndFullName}
                          data-testid={`rankAndFullName-${section.id}`}
                          aria-label={`${section.id} rank and full name`}
                          name="rankAndFullName"
                          key="rankAndFullName"
                          onChange={(e) => {
                            handleInputValidations(
                              e,
                              'rankAndFullName',
                              false,
                              section.id,
                            );
                          }}
                          onBlur={(e) => {
                            handleOnBlur();
                            handleInputValidations(
                              e,
                              'rankAndFullName',
                              false,
                              section.id,
                            );
                          }}
                        />
                      </FieldFeedback>
                    </div>
                  </div>

                  <div className="military-address">
                    <Label
                      htmlFor="military-address-label"
                      required
                      className={[
                        'address-label',
                        visibility.militaryOrDiplomaticAddress === 'error'
                          ? 'usa-label--error'
                          : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      APO, FPO or DPO address
                    </Label>
                    <div className="typeahead-section common-style">
                      <FieldFeedback
                        text={feedbackText.militaryOrDiplomaticAddress}
                        visible={visibility.militaryOrDiplomaticAddress}
                        id="militaryAddressFeedback"
                        applyTo={(child) => child.props.type === 'text'}
                      >
                        <Textbox
                          id="militaryOrDiplomaticAddress"
                          required
                          aria-label={`${section.id} APO, FPO or DPO address`}
                          value={addressFieldState.militaryOrDiplomaticAddress}
                          key="militaryOrDiplomaticAddress"
                          data-testid={`militaryOrDiplomaticAddress-${section.id}`}
                          name="militaryOrDiplomaticAddress"
                          onChange={(e) => {
                            handleFieldChange(
                              e.target.value,
                              'militaryOrDiplomaticAddress',
                              section.id,
                            );
                            handleInputValidations(
                              e,
                              'militaryOrDiplomaticAddress',
                              true,
                              section.id,
                            );
                          }}
                          onBlur={(e) => {
                            handleOnBlur();
                            handleInputValidations(
                              e,
                              'militaryOrDiplomaticAddress',
                              false,
                              section.id,
                            );
                          }}
                        />
                      </FieldFeedback>
                    </div>
                  </div>

                  <div
                    className="post-office"
                    data-testid={`post-office-section-${section.id}`}
                  >
                    <Label
                      htmlFor="address-label"
                      required
                      className={[
                        'address-label',
                        visibility.postOffice === 'error'
                          ? 'usa-label--error'
                          : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      Post office
                    </Label>
                    <div className="post-office-section">
                      <FieldFeedback
                        text={feedbackText.postOffice}
                        visible={visibility.postOffice}
                        id="postOfficeFeedback"
                        applyTo={(child) => child.props.name === 'postOffice'}
                      >
                        <ComboBox
                          data-testid={`select-post-office-${section.id}`}
                          id="postOffice"
                          aria-label={`${section.id} post office`}
                          name={`post-office-${section.id}`}
                          defaultValue={checkDefaultPostOfficeValue()}
                          options={postOfficeLabels}
                          onChange={(e) => {
                            if (!e) {
                              // e would be undefined when the combobox is cleared,
                              // so it should be safe to assume that it's the same as
                              // the 'onblur' event
                              handleOnBlur();
                              handleFieldChange('', 'postOffice', section.id);
                              handleInputValidations(
                                '',
                                'postOffice',
                                false,
                                section.id,
                              );
                            } else {
                              let val = e || '';
                              if (val === 'select') val = '';
                              handleFieldChange(val, 'postOffice', section.id);
                              handleInputValidations(
                                val,
                                'postOffice',
                                true,
                                section.id,
                              );
                            }
                          }}
                        />
                      </FieldFeedback>
                    </div>
                  </div>

                  <div className="zip-section">
                    <Label
                      htmlFor="address-label"
                      required
                      className={[
                        'address-label',
                        visibility.zipcode === 'error'
                          ? 'usa-label--error'
                          : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      {internationalAddress ? 'Postal Code' : 'Zip Code'}
                    </Label>
                    <FieldFeedback
                      text={feedbackText.zipcode}
                      visible={visibility.zipcode}
                      id="zip1AddressFeedback"
                      applyTo={(child) => child?.props?.type === 'text'}
                    >
                      <div
                        className={
                          internationalAddress
                            ? 'postal-section-input'
                            : 'zip-section-input'
                        }
                      >
                        <Textbox
                          id="zipcode"
                          required
                          aria-label={`${section.id} zip code`}
                          data-testid={`zipcode-${section.id}`}
                          value={addressFieldState.zipcode}
                          key="zipcode"
                          name="zipcode"
                          onChange={(e) => {
                            handleFieldChange(
                              e.target.value,
                              'zip-1',
                              section.id,
                            );
                            handleInputValidations(
                              e,
                              'zipcode',
                              true,
                              section.id,
                            );
                          }}
                          onBlur={(e) => {
                            handleOnBlur();
                            handleInputValidations(
                              e,
                              'zipcode',
                              false,
                              section.id,
                            );
                          }}
                        />
                      </div>
                    </FieldFeedback>
                  </div>
                  <div className="email-address">
                    <Label
                      htmlFor="address-label"
                      required
                      className={[
                        'address-label',
                        visibility.email === 'error' ? 'usa-label--error' : '',
                      ]
                        .filter((i) => i !== '')
                        .join(' ')}
                    >
                      Email
                    </Label>
                    <div>
                      <FieldFeedback
                        text={feedbackText.email}
                        visible={visibility.email}
                        id="emailAddressFeedback"
                        applyTo={(child) => child.props.type === 'text'}
                      >
                        <Textbox
                          id="email"
                          value={addressFieldState.email}
                          aria-label={`${section.id} email`}
                          data-testid={`email-address-${section.id}`}
                          key="email"
                          name="email"
                          onChange={handleEmailAddressInputValidation}
                          onBlur={(e) => {
                            handleOnBlur();
                            handleInputValidations(
                              e,
                              'email',
                              false,
                              section.id,
                            );
                          }}
                        />
                      </FieldFeedback>
                    </div>
                  </div>

                  <div className="point-of-contact-details phone-input">
                    <PhoneFaxInput
                      type="phone"
                      countryCodeProps={{
                        options: callingCodesModifiedData,
                        errorMessage: visibility.phoneCountryCode
                          ? feedbackText.phoneCountryCode
                          : null,
                        value: getCountryCodeValue(
                          addressFieldState.phoneCountryCode,
                        ),
                        defaultValue: getCountryCodeValue(
                          addressFieldState.phoneCountryCode,
                        ),
                        'aria-label': `${section.id} country code`,
                        label: 'Country code',
                        required: true,
                        onChange(newValue) {
                          if (!newValue) return;
                          handleFieldChange(
                            newValue?.split(':')[0] || '',
                            'phoneCountryCode',
                            section.id,
                          );
                        },
                        'data-testid': `country-code-${section.id}`,
                      }}
                      phoneFaxProps={{
                        errorMessage: visibility.phoneNumber
                          ? feedbackText.phoneNumber
                          : null,
                        label: 'Phone',
                        'aria-label': `${section.id} phone number`,
                        required: true,
                        defaultValue: addressFieldState.phoneNumber,
                        onChange(e) {
                          handleFieldChange(
                            e.target.value,
                            'phoneNumber',
                            section.id,
                          );
                        },
                        'data-testid': `phoneNumber-${section.id}`,
                      }}
                      extensionProps={{
                        errorMessage: visibility.phoneExtension
                          ? feedbackText.phoneExtension
                          : null,
                        defaultValue: addressFieldState.phoneExtension,
                        label: 'Extension',
                        'aria-label': `${section.id} phone extension number`,
                        onChange(e) {
                          handleFieldChange(
                            e.target.value,
                            'phoneExtension',
                            section.id,
                          );
                        },
                        'data-testid': `extension1-${section.id}`,
                      }}
                    />
                  </div>

                  <div className="fax-number phone-input">
                    <PhoneFaxInput
                      type="fax"
                      countryCodeProps={{
                        options: callingCodesModifiedData,
                        errorMessage: visibility.faxCountryCode
                          ? feedbackText.faxCountryCode
                          : null,
                        value: getCountryCodeValue(
                          addressFieldState.faxCountryCode,
                        ),
                        'aria-label': `${section.id} country code`,
                        defaultValue: getCountryCodeValue(
                          addressFieldState.faxCountryCode,
                        ),
                        onChange(newValue) {
                          if (!newValue) return;
                          handleFieldChange(
                            newValue?.split(':')[0] || '',
                            'faxCountryCode',
                            section.id,
                          );
                        },
                      }}
                      phoneFaxProps={{
                        defaultValue: addressFieldState.faxNumber,
                        errorMessage: visibility.faxNumber
                          ? feedbackText.faxNumber
                          : null,
                        label: 'Fax',
                        'aria-label': `${section.id} fax number`,
                        onChange(e) {
                          handleFieldChange(
                            e.target.value,
                            'faxNumber',
                            section.id,
                          );
                        },
                        'data-testid': `address-fax2-section-mailing-${section.id}`,
                      }}
                      extensionProps={{
                        errorMessage:
                          visibility.faxExtension === 'error'
                            ? feedbackText.faxExtension
                            : null,
                        defaultValue: addressFieldState.faxExtension,
                        'aria-label': `${section.id} fax extension number`,
                        onChange(e) {
                          handleFieldChange(
                            e.target.value,
                            'faxExtension',
                            section.id,
                          );
                        },
                        'data-testid': `extension2-${section.id}`,
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        )}
      </div>
    </>
  );
};

AddressComponent.propTypes = {
  section: PropTypes.instanceOf(Object),
  countriesData: PropTypes.instanceOf(Array),
  callingCodes: PropTypes.instanceOf(Array),
  statesData: PropTypes.instanceOf(Array),
  handleFieldChange: PropTypes.func.isRequired,
  handleAddressCheckbox: PropTypes.func.isRequired,
  handleInputValidations: PropTypes.func.isRequired,
  addressFieldState: PropTypes.instanceOf(Object),
  visibility: PropTypes.instanceOf(Object).isRequired,
  feedbackText: PropTypes.instanceOf(Object).isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  deliveryAddressOption: PropTypes.instanceOf(Object),
  standardItemCodeFASTDescription: PropTypes.string,
  handleStepper: PropTypes.func,
  handleDealershipDeliveryAddressChange: PropTypes.func,
  selectedContract: PropTypes.instanceOf(Object),
  requisitionType: PropTypes.string,
  selectedContractAgencyInformation: PropTypes.shape({
    agencyName: PropTypes.string,
    agency: PropTypes.string,
  }),
};

AddressComponent.defaultProps = {
  section: {},
  countriesData: [],
  callingCodes: [],
  statesData: [],
  addressFieldState: {},
  deliveryAddressOption: {},
  standardItemCodeFASTDescription: '',
  handleStepper: () => {},
  handleDealershipDeliveryAddressChange: () => {},
  selectedContract: {},
  requisitionType: 'STANDARD',
  selectedContractAgencyInformation: {},
};

export default AddressComponent;

import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Accordion,
  TextInput,
  useModal,
  connectModal,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { applyFormErrorsRecursively } from '@gsa/afp-shared-form-utils';
import useBulkOrderModsState from './useBulkOrderModsState';
import OrderModChangeSet from './OrderModChangeSet';
import OrderModificationHeader from './OrderModificationHeader';
import { CANCEL_ORDER_MOD_CHANGESET } from '../../services/data-layer';
import RemoveOrderModChangeSetModal from './RemoveOrderModChangeSetModal';
import CancelSvg from '../../assets/images/cancel.svg';
import { StoreOperations, StoreSubjects } from '../../constants/constants';
import {
  addressFormHasChanges,
  addressFormHasChangesFromOrder,
  contactFormHasChanges,
  validateSelectedQuantityChange,
} from './UpdateOrderModHelpers';
import OrderModQuantityChangeAlertModal from './change-sets/OrderModQuantityChangeAlertModal';
import { hasNonEmptyData } from '../../utilities/commonUtils';
import OrderModMessageLines from './OrderModMessageLines';

export const OrderModSteps = {
  CREATE_ORDER_MOD: 1,
  SELECT_ORDER_MOD: 2,
  UPDATE_ORDER_MOD: 3,
  SUBMIT_ORDER_MOD: 4,
};
export const ChangeSetType = {
  MODIFY: 'MODIFY',
  TERMINATE: 'TERMINATE',
};
const UpdateOrderMod = () => {
  const ability = useAppAbility();
  const canTerminateOrder = useMemo(
    () => ability?.can(StoreOperations.Cancel, StoreSubjects.OrderVehicle),
    [ability],
  );
  const [additionalCommentError, setAdditionalCommentError] = useState(false);
  const [allOrdersForTermination, setAllOrdersForTermination] = useState(false);
  const [ordersAffected, setOrdersAffected] = useState([]);

  const {
    currentStep,
    selectedOrdersForMod,
    setSelectedOrdersForMod,
    setCurrentStep,
    updateOrderModChangeSetMutation,
    terminateOrderModificationMutation,
    additionalComment,
    setAdditionalComment,
    currentChangeSet,
    currentChangeSetType,
    setCurrentChangeSetType,
    changeSets,
    orderModHasError,
    setSelectedFilters,
    setOrderModRows,
    setCurrentChangeSet,
    getOrderModification,
    orderChanges,
    optedForTermination,
    setOptedForTermination,
    mailingSameAsRequisition,
    deliverySameAsRequisition,
    formGeneratorRef,
    orderModAttachmentTypes,
    setChangeSetError,
    updateOrderModChangeSetOrders,
    setSubmitOrderModHasError
  } = useBulkOrderModsState();
  const removeOrders = (order) => {
    const filteredOrders = currentChangeSet?.changeSetOrders?.filter(
      (orderMod) => orderMod.orderId !== order.orderId,
    );

    setSelectedOrdersForMod(
      filteredOrders?.map((changeSetOrder) => {
        return {
          caseNumber: changeSetOrder?.order?.caseNumber,
          standardItemId: changeSetOrder?.order?.standardItemId,
          quantity: changeSetOrder?.order?.quantity,
          unitPriceToCustomer: changeSetOrder?.order?.unitPriceToCustomer,
          orderId: changeSetOrder?.orderId,
          scheduleLine: changeSetOrder?.order?.scheduleLine,
          contractLineId: changeSetOrder?.order?.contractLineId,
          requisitionType: changeSetOrder?.order?.requisitionType,
          requisitionNumber: changeSetOrder?.order?.requisitionNumber,
          orderVehicles: changeSetOrder?.order?.orderVehicles,
          requisitionerAddress: changeSetOrder?.order?.requisitionerAddress,
          requisitionerContact: changeSetOrder?.order?.requisitionerContact,
          mailingAddress: changeSetOrder?.order?.mailingAddress,
          mailingContact: changeSetOrder?.order?.mailingContact,
          deliveryAddress: changeSetOrder?.order?.deliveryAddress,
          deliveryContact: changeSetOrder?.order?.deliveryContact,
          rest: { ...changeSetOrder?.order },
        };
      }),
    );

    let orderIds = [];
    if (!filteredOrders?.length) {
      setCurrentStep(OrderModSteps.SELECT_ORDER_MOD);
    } else {
      orderIds = filteredOrders.map((o) => o.orderId);
    }

    updateOrderModChangeSetOrders(orderIds);
  };
  const removeModal = useModal();
  const DisplayRemoveCSModal = connectModal(RemoveOrderModChangeSetModal);
  const onRemoveModalClose = () => {
    removeModal.closeModal();
  };
  const qtyChangeAlertModal = useModal();
  const DisplayQtyChangeAlertModal = connectModal(
    OrderModQuantityChangeAlertModal,
  );

  const [cancelOrderModCSMutation] = useMutation(CANCEL_ORDER_MOD_CHANGESET, {
    onError: (error) => {
      console.log(error);
      onRemoveModalClose();
    },
    onCompleted: async (data) => {
      if (data) {
        getOrderModification();
        if (changeSets?.length === 1) {
          setOrderModRows([]);
          setCurrentChangeSet({
            type: 'MODIFY',
          });
          setSelectedFilters([]);
          setSelectedOrdersForMod([]);
          setCurrentChangeSetType('MODIFY');
        }
        setCurrentStep(OrderModSteps.SUBMIT_ORDER_MOD);
      }
      onRemoveModalClose();
    },
  });
  const onRemoveCSSubmit = () => {
    if (currentChangeSet) {
      cancelOrderModCSMutation({
        variables: {
          changeSetId: currentChangeSet?.orderChangeSetModId,
        },
      });
    }
  };
  useEffect(() => {
    if (
      currentChangeSet?.type === ChangeSetType.MODIFY ||
      currentChangeSetType === ChangeSetType.MODIFY
    ) {
      setOptedForTermination(false);
    } else if (
      currentChangeSet?.type === ChangeSetType.TERMINATE ||
      currentChangeSetType === ChangeSetType.TERMINATE
    ) {
      setOptedForTermination(true);
    }
    setAdditionalComment(currentChangeSet?.additionalComments);
  }, [currentChangeSet]);

  const applyFormErrors = (errors, setError, clearErrors, baseErrors, path) => {
    let hasErrors = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in errors) {
      if (!errors[key].message && typeof errors[key] === 'object') {
        hasErrors = applyFormErrors(
          errors[key],
          setError,
          clearErrors,
          baseErrors,
          [path, key].filter(Boolean).join('.'),
        );
      } else {
        hasErrors = applyFormErrorsRecursively(
          [path, key].filter(Boolean).join('.'),
          baseErrors,
          setError,
          clearErrors,
        );
      }
    }

    return hasErrors;
  };

  const validateChangedForm = async (key) => {
    const { getValues, setError, clearErrors } = formGeneratorRef[key].current;
    clearErrors();

    const { errors } = await formGeneratorRef[key].current.validate(
      getValues(),
      {},
      {},
      true,
    );

    applyFormErrors(errors, setError, clearErrors, errors, '');
    if (Object.keys(errors).length > 0) {
      setChangeSetError('Some of the address/contact fields are required');
      return true;
    }
    return false;
  };

  const clickSubmitAndContinue = async () => {
    const hasErrors = [];
    setChangeSetError('');
    setSubmitOrderModHasError(false);
    const validateData = { ...orderChanges };
    delete validateData.attachments;
    const validateChangeSetData = hasNonEmptyData(validateData);
    if (!validateChangeSetData && !optedForTermination) {
      setChangeSetError(
        'No modifications have been applied to the change set. To proceed, please select at least one modification',
      );
    } else {
      if (
        orderChanges?.selectedOptions?.some(
          (option) => option?.inReq && option?.optionCode !== 'CPT',
        )
      ) {
        const attachmentType = orderModAttachmentTypes?.find(
          (aType) => aType?.type === 'OPTIONS_DOC',
        );

        if (!orderChanges?.attachments?.length) {
          hasErrors.push(true);
          setChangeSetError('Please upload a option details document');
        } else {
          // find any attachment with the type of paint and graphics
          const optionDetailsAttachment = orderChanges?.attachments?.find(
            (attachment) => attachment.typeId === attachmentType?.id,
          );

          if (!optionDetailsAttachment) {
            hasErrors.push(true);
            setChangeSetError('Please upload a option details document');
          }
        }
      }
      if (
        orderChanges?.orderChangeType === 'quantity' &&
        orderChanges?.selectedOptions?.length > 0
      ) {
        if (
          orderChanges?.selectedOptions?.some(
            (option) => parseInt(option.optionTotalPrice, 10) !== 0,
          )
        ) {
          hasErrors.push(true);
          setChangeSetError(
            'selected options on order(s) lead to a price change. price change and quantity change cannot be applied at the same time',
          );
        }
      }
      if (hasErrors.some((i) => i === true) && (!optedForTermination || currentChangeSet?.type === ChangeSetType.MODIFY )) return;

      if (
        orderChanges?.selectedOptions?.some(
          (option) => option.optionCode === 'CPT',
        )
      ) {
        const attachmentType = orderModAttachmentTypes?.find(
          (aType) => aType?.type === 'PAINT_AND_GRAPHICS',
        );

        if (!orderChanges?.attachments?.length) {
          hasErrors.push(true);
          setChangeSetError('Please upload a paint and graphics attachment');
        } else {
          // find any attachment with the type of paint and graphics
          const paintAndGraphicsAttachment = orderChanges?.attachments?.find(
            (attachment) => attachment.typeId === attachmentType?.id,
          );

          if (!paintAndGraphicsAttachment) {
            hasErrors.push(true);
            setChangeSetError('Please upload a paint and graphics attachment');
          }
        }
      }

      if (mailingSameAsRequisition && orderChanges.requisitionAddress) {
        orderChanges.mailingAddress = orderChanges.requisitionAddress;
        orderChanges.mailingAddress.isSameAsRequisition = true;
        orderChanges.mailingContact = orderChanges.requisitionContact;
      }

      if (deliverySameAsRequisition && orderChanges.requisitionAddress) {
        orderChanges.deliveryAddress = orderChanges.requisitionAddress;
        orderChanges.deliveryAddress.isSameAsRequisition = true;
        orderChanges.deliveryContact = orderChanges.requisitionContact;
      }

      let requisitionAddressChanged = false;
      let mailingAddressChanged = false;
      let deliveryAddressChanged = false;

      // when there is only one order, we need to compare the form values with the order values
      // in an attempt to extend the original order, not requiring the user to fill out all of
      // the information
      if (selectedOrdersForMod.length === 1) {
        requisitionAddressChanged = addressFormHasChangesFromOrder(
          selectedOrdersForMod[0].requisitionerAddress,
          selectedOrdersForMod[0].requisitionerContact,
          orderChanges.requisitionAddress,
          orderChanges.requisitionContact,
        );

        mailingAddressChanged = addressFormHasChangesFromOrder(
          selectedOrdersForMod[0].mailingAddress,
          selectedOrdersForMod[0].mailingContact,
          orderChanges.mailingAddress,
          orderChanges.mailingContact,
        );

        deliveryAddressChanged = addressFormHasChangesFromOrder(
          selectedOrdersForMod[0].deliveryAddress,
          selectedOrdersForMod[0].deliveryContact,
          orderChanges.deliveryAddress,
          orderChanges.deliveryContact,
        );

        // however if there are more than one order, we need to do a complete validation
        // of the form values because the user will be required to fill out the complete form
      } else {
        requisitionAddressChanged = addressFormHasChanges(
          'requisitionAddress',
          orderChanges.requisitionAddress,
          orderChanges.requisitionContact,
        );
        mailingAddressChanged = addressFormHasChanges(
          'mailingAddress',
          orderChanges.mailingAddress,
          orderChanges.mailingContact,
        );
        deliveryAddressChanged = addressFormHasChanges(
          'deliveryAddress',
          orderChanges.deliveryAddress,
          orderChanges.deliveryContact,
        );
      }

      const deliveryInformationChanged =
        requisitionAddressChanged ||
        mailingAddressChanged ||
        deliveryAddressChanged;

      if (deliveryInformationChanged && currentChangeSet?.type === ChangeSetType.MODIFY) {
        hasErrors.push(await validateChangedForm('requisitionAddress'));

        if (!mailingSameAsRequisition) {
          hasErrors.push(await validateChangedForm('mailingAddress'));
        }

        if (!deliverySameAsRequisition) {
          hasErrors.push(await validateChangedForm('deliveryAddress'));
        }
      }

      if (contactFormHasChanges(orderChanges?.agencyContact)) {
        hasErrors.push(await validateChangedForm('agencyContact'));
      }

      if (hasErrors.some((i) => i === true) && (!optedForTermination || currentChangeSet?.type === ChangeSetType.MODIFY )) return;

      let ordersLeadsToTermination = false;
      if (
        selectedOrdersForMod.length > 0 &&
        orderChanges?.orderChangeType === 'quantity' &&
        orderChanges?.quantityChangePerOrder &&
        !optedForTermination
      ) {
        const result = validateSelectedQuantityChange(
          selectedOrdersForMod,
          orderChanges.quantityChangePerOrder,
        );
        if (result) {
          qtyChangeAlertModal.openModal();
          setAllOrdersForTermination(result.allOrdersForTermination);
          setOrdersAffected(result.orderAffected);
          ordersLeadsToTermination = true;
        }
      }
      let formHasErrors = [];
      if (orderModHasError && Object.keys(orderModHasError).length > 0) {
        formHasErrors = orderModHasError.filter(
          (error) => error.visibility === 'error',
        );
      }
      if (!additionalComment) {
        setAdditionalCommentError(true);
      }
      if (
        additionalComment &&
          ((formHasErrors.length === 0 &&
        !ordersLeadsToTermination) || optedForTermination)
      ) {
        qtyChangeAlertModal.closeModal();
        setAdditionalCommentError(false);
        if (!formHasErrors.length > 0) {
          setCurrentStep(
            currentChangeSet?.orderChangeSetModId
              ? OrderModSteps.SUBMIT_ORDER_MOD
              : OrderModSteps.UPDATE_ORDER_MOD,
          );

          updateOrderModChangeSetMutation(null, !deliveryInformationChanged);
        }
      }
    }
  };

  const navigateBackToSelectOrdersStep = () => {
    setCurrentStep(OrderModSteps.SELECT_ORDER_MOD);
    updateOrderModChangeSetMutation(null, true, true);
  };

  const getOrderChangeSetContent = () => {
    return [
      {
        orderChangeSetModId: 'orderChangeSet',
        title: (
          <div className="add-options-accordion-title">
            {currentChangeSet?.changeSetOrders?.length} selected orders
          </div>
        ),
        content: (
          <div className="changeset-order-container">
            <div className="changeset-order-title">
              <h2>
                {currentChangeSet?.changeSetOrders?.length} selected orders
              </h2>
              <Button
                leftIcon={{ name: 'arrow_back' }}
                variant="outline"
                onClick={navigateBackToSelectOrdersStep}
                label="Find and add more orders"
              />
            </div>
            {currentChangeSet?.changeSetOrders?.map((order) => {
              return (
                <div className="changeset-order-list">
                  <div className="grid-col-4">
                    <Button
                      variant="unstyled"
                      onClick={() => {}}
                      label={order.order.caseNumber}
                    />
                  </div>
                  <div className="grid-col-4">
                    <Button
                      leftIcon={{ name: 'close' }}
                      variant="unstyled"
                      onClick={() => removeOrders(order)}
                      label="Remove from set"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ),
        expanded: true,
      },
    ];
  };

  return currentStep === OrderModSteps.UPDATE_ORDER_MOD ? (
    <>
      {/*      <div className="create-order-mod-title">
        <PageTitle
          data-testid="requisitions-and-orders-header"
          title={updatedDraft?.orderModName}
        />

        <span className="status-badge">
          <StatusBadge variant={OrderModStatusType.DRAFT_MOD.color}>
            {OrderModStatusType.DRAFT_MOD.label}
          </StatusBadge>
        </span>
      </div>

      <p className="requisitions-and-orders-header-desc">
        Guis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit
        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat c
      </p>
    */}
      <OrderModificationHeader />
      <div className="change-set-title-section">
        <h2 className="change-set-title">
          Change set {currentChangeSet?.changeSetNumber || 1}:
        </h2>
        <span
          onClick={() => {
            removeModal.openModal();
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              removeModal.openModal();
            }
          }}
          role="button"
          tabIndex={0}
          className="order-mod-cancel-button"
        >
          <img
            data-testid="remove-order-mod-cs-icon"
            className="order-mod-cancel-icon"
            src={CancelSvg}
            alt="CancelSvg"
          />
          Remove this change set
        </span>
      </div>
      <strong>Modify or terminate selected orders</strong>

      <div className="accordion-group-custom">
        <Accordion
          multiselectable={false}
          bordered="true"
          items={getOrderChangeSetContent()}
          className="add-options-accordion"
        />
      </div>
      {(!optedForTermination ||
        currentChangeSetType === ChangeSetType.MODIFY) && (
        <div>
          <div className="select-order-container">
            <div className="grid-col-8">
              <h3>Modify orders:</h3>
              <p>
                Open sections below to access order details and modify all
                orders within this change set.
              </p>
            </div>
            <div className="grid-col-4">
              {canTerminateOrder && (
                <Button
                  label="Terminate these orders"
                  type="button"
                  variant="secondary"
                  data-testid="terminate-selected-orders"
                  leftIcon={{ name: 'close' }}
                  onClick={() => {
                    setOptedForTermination(true);
                    setCurrentChangeSetType(ChangeSetType.TERMINATE);
                    setCurrentChangeSet({
                      ...currentChangeSet,
                      type: 'TERMINATE',
                    });
                    terminateOrderModificationMutation();
                  }}
                />
              )}
            </div>
          </div>
          <OrderModChangeSet />
        </div>
      )}
      {(optedForTermination || currentChangeSet?.type === ChangeSetType.TERMINATE) && (
        <div className="terminate-order-container">
          <h3>
            {' '}
            You are terminating {currentChangeSet?.changeSetOrders?.length}{' '}
            orders{' '}
          </h3>
          <p>
            Are you sure you want to terminate these orders? Once this action is
            taken, it cannot be undone.
          </p>
          <Button
            label="Undo termination"
            type="button"
            variant="outline"
            data-testid="undo-termination"
            leftIcon={{ name: 'undo' }}
            onClick={() => {
              setCurrentChangeSetType(ChangeSetType.MODIFY);
              setOptedForTermination(false);
              setCurrentChangeSet({
                  ...currentChangeSet,
                    type: 'MODIFY',
              });
            }}
          />
        </div>
      )}

      <div className="additional-order-container">
        <h4>
          Additional comments <span className="text-red text-bold">*</span>
        </h4>

        <div className="error-text-desc" data-testid="veh-error-text">
          {additionalCommentError ? 'This is a required field' : ''}
        </div>
        <TextInput
          type="textarea"
          value={additionalComment}
          aria-label="additional comments"
          onChange={(e) => {
            if (e.target.value.length > 0) {
              setAdditionalCommentError(false);
            } else {
              setAdditionalCommentError(true);
            }
            setAdditionalComment(e.target.value);
          }}
          data-testid="additional-change-set-comments"
          characterLimit={500}
        />

        <OrderModMessageLines />
      </div>

      <div className="button-section">
        <Button
          variant="outline"
          type="button"
          data-testid="update-order-prev-btn"
          onClick={navigateBackToSelectOrdersStep}
          leftIcon={{ name: 'arrow_back' }}
          label="Previous"
        />
        <Button
          variant="primary"
          data-testid="update-order-next-btn"
          type="button"
          onClick={clickSubmitAndContinue}
          rightIcon={{ name: 'arrow_forward' }}
          label="Save and continue"
        />
      </div>
      <DisplayRemoveCSModal
        isOpen={removeModal.isOpen}
        onSubmit={() => onRemoveCSSubmit()}
        onClose={() => onRemoveModalClose()}
      />
      <DisplayQtyChangeAlertModal
        isOpen={qtyChangeAlertModal.isOpen}
        onClose={() => qtyChangeAlertModal.closeModal()}
        allForTermination={allOrdersForTermination}
        ordersForTermination={ordersAffected}
      />
    </>
  ) : null;
};

export default UpdateOrderMod;

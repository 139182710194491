import { StoreOperations, StoreSubjects } from '../constants/constants';
import { UserTypes } from '../constants';

export const canUpdateStatusOnOrderVehicle = (ability) =>
  ability?.can(StoreOperations.updateStatus, StoreSubjects.OrderVehicle);

export const canEngineerReviewRequisitionCart = (ability) =>
  ability?.can(StoreOperations.EngineerReview, StoreSubjects.Requisition);

export const canApproveRequisitionCart = (ability) =>
  ability?.can(StoreOperations.Approve, StoreSubjects.Requisition);

export const canCreateRequisitionCart = (ability) =>
  ability?.can(StoreOperations.Create, StoreSubjects.Requisition);

export const canUpdateRequisitionCart = (ability) =>
  ability?.can(StoreOperations.Update, StoreSubjects.Requisition);

export const isInternalUser = (currentUser) =>
  currentUser?.userType?.id === UserTypes.GSA_EMPLOYEE;
